<template>
<opm-page :show-modal="showModal" @hide="showModal = false" :wx-share="wxShare">
  <ion-content>
    <swiper :slides-per-view="1" :loop="true" :autoplay="{ delay: 3000, disableOnInteraction: false}" :pagination="{ 'dynamicBullets': true }">
      <swiper-slide v-for="(photo, ind) in house.houseImages.split(',')" :key="ind">
        <ion-img :src="house.imageHost + photo"/>
      </swiper-slide>
    </swiper>
    <div class="ion-padding">
      <div class="flex-between">
        <div>
          <span class="tag" :class="ind === 0 ? 'orange' : ''" v-for="(tag, ind) in house.tags.split(',')" :key="ind">
            {{ tag }}
          </span>
        </div>
        <div>
          <ion-label style="color:grey;font-size:16px" @click="showModal = true">
            <ion-icon slot="start" :icon="starOutline"></ion-icon>
          </ion-label>
        </div>
      </div>
      <h2 style="font-weight: 600">{{ house.district }} | {{ house.address }}</h2>
      <div>
        <div style="color: red;display: inline-block">
          <span style="font-size: 12px">$</span><span style="font-size:20px">{{ house.audPrice }}</span><span style="font-size: 12px">/{{ house.timeUnit }}</span>
        </div>
      </div>
      <div style="margin-top: 16px;" class="flex-between">
        <div>
          <div class="room-title">{{ house.houseType }}</div>
          <div class="room-subtitle">户型</div>
        </div>
        <div>
          <div class="room-title">{{ house.roomCount }}房{{ house.toiletCount }}卫{{ house.parkCount }}车位</div>
          <div class="room-subtitle">房型</div>
        </div>
        <div>
          <div class="room-title">{{ $filters.dayjs(house.checkinTime, 'YYYY-MM-DD') }}</div>
          <div class="room-subtitle">可入住时间</div>
        </div>
      </div>
      <div style="margin-top: 30px">
        <div><h4>整租介绍</h4></div>
        <div class="card">
          <div style="font-size: 0.8em;color: #34394D;margin-top: 7px">
            <div style="vertical-align: middle;display: inline-block">
              <img :src="`/assets/bulb.svg`" width="24" height="24" />
            </div>
            <div style="display: inline-block;margin-left:8px">房屋亮点</div>
          </div>
          <div style="color: #33394E; font-size: 17px;margin-left: 32px">{{ house.highlight }}</div>
          <div style="font-size: 0.8em;color: #34394D;margin-top: 7px">
            <div style="vertical-align: middle;display: inline-block">
              <img :src="`/assets/clock.svg`" width="24" height="24" />
            </div>
            <div style="display: inline-block;margin-left:8px">租期时间</div>
          </div>
          <div style="color: #33394E; font-size: 17px;margin-left: 32px">{{ house.rentalPeriod }}</div>
          <div style="font-size: 0.8em;color: #34394D;margin-top: 7px">
            <div style="vertical-align: middle;display: inline-block">
              <img :src="`/assets/paw.svg`" width="24" height="24" />
            </div>
            <div style="display: inline-block;margin-left:8px">宠物政策</div>
          </div>
          <div style="color: #33394E; font-size: 17px;margin-left: 32px">{{ house.petRule }}</div>
        </div>
      </div>
      <div style="margin-top: 30px">
        <div><h4>配套设施</h4></div>
        <div style="display: flex;flex-wrap: wrap;">
          <div v-for="(f, ind) in house.facilities.split(',')" :key="ind" style="display: flex;flex-direction: column;align-items: center;width: calc(100% / 5);margin-bottom: 20px">
            <img :src="`/assets/facilities/${f}.svg`" width="24" height="24" />
            <span style="font-size: 12px; color: #33394e;margin-top: 5px">{{ f }}</span>
          </div>
        </div>
      </div>
      <div style="margin-top: 30px">
        <p v-html="house.detail"></p>
      </div>
    </div>
  </ion-content>
  <ion-item lines="none" style="box-shadow: 0px -5px 10px #ddd;padding:10px 0 15px;">
    <div slot="start">
      <div style="font-size: 10px">租金</div>
      <div style="color: red; font-size: 20px;font-weight: bold">${{ house.audPrice }}<span style="font-size: 13px">/{{ house.timeUnit }}</span></div>
    </div>
    <div slot="end">
      <ion-button size="large" style="color: #fff" @click="showModal = true">在线咨询</ion-button>
    </div>
  </ion-item>
</opm-page>
</template>

<script type="ts">
import OpmPage from "@/components/OpmPage";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { IonContent, IonItem, IonLabel, IonButton, IonImg, IonIcon, IonGrid, IonRow, IonCol  } from '@ionic/vue';
import { starOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import 'swiper/swiper-bundle.css';
import {useRoute} from "vue-router";

SwiperCore.use([Pagination, Autoplay])
export default defineComponent({
  name: "Project",
  data() {
    return {
      starOutline,
      route: useRoute(),
      house: { tags: '', facilities: '', floorPlans:'', houseImages: '' },
      showModal: false,
      wxShare: {}
    }
  },
  mounted() {
    this.$axios.get(`rental/house/${this.route.params.id}`).then(resp => {
      this.house = resp.result
      document.title = '【鱼掌门App】' +  this.house.district + this.house.address
      const imgUrl = this.house.imageHost + this.house.houseImages.split(',')[0]
      this.wxShare = { title: document.title, desc: document.title, link: location.href, imgUrl: imgUrl }
    })
  },
  methods: {

  },
  components: {
    OpmPage,
    IonContent,
    IonItem,
    IonLabel,
    IonButton,
    IonImg,
    Swiper,
    SwiperSlide,
    IonIcon,
  }
})
</script>

<style scoped>
.tag {
  margin-right: 5px;padding: 3px;font-size:10px;
  border: 1px solid #dedede;
}
.tag.orange {
  border: 0;
  padding: 4px;
  color: #fff; background: #FF640A;
}
.card {
  border-radius: 5px;
  box-shadow: 0px 0px 10px #ddd;
  width: 98%;
  padding: 10px 17px;
  margin: 0 auto;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.room-title{
  font-size: 18px;
  color: #34394E;
}
.room-subtitle {
  font-size: 12px; color: #8F93A0
}
</style>